import React, {Fragment, useState, useEffect } from 'react';
import {helmet } from 'react-helmet';
import {Link, redirect } from "react-router-dom";
import AllProd from '../components/AllProd';

const axios = require('axios');


export default function Home() {
   
    return(
        <div className="">
            <AllProd />
        </div>
            )
}