import React, {useEffect, useState} from 'react';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
//import axios from 'axios';
import { useCookies } from 'react-cookie';
//import { urlencoded } from 'express';

function AllProd() {
  const buttonvariants = {
    hover:{
      scale:1.1,
      textShadow : "0px 0px 8px rgb(255,255,255)",
      boxShadow : "0px 0px 8px rgb(255,255,255)",
      transition:{
        yoyo:Infinity
      }
    }
  }

  return (
    <div>
        <div className="w-full h-auto flex flex-col justify-center bg-transparent overflow-hidden " >
                    
        <div className="w-full h-screen flex flex-row justify-center bg-transparent overflow-hidden" id="block1">
              <div className="w-1/2 h-full flex flex-row  items-center pl-8">
                  <span className="uppercase text-9xl font-black text-left text-yellow-500">la livraison express en Algérie</span>

              </div>
            
              <div className="w-1/2 h-full flex flex-col justify-center items-center">
                  <div className="w-1/2 h-1/2 flex flex-col justify-center items-center rounded-lg opacity-70 hover:opacity-100 hover:bg-yellow-500 px-4  py-2 bg-yellow-200">
                    <span className="text-lg font-bold text-black uppercase">suivre mon colis 1.</span>
                    <input type="text" className=" pl-4 w-full h-12 outline-none rounded-md border-2 border-gray-800 bg-transparent focus:border-gray-800 mb-4" />
                    <motion.button 
                                variants={buttonvariants}
                                whileHover="hover"
                                className="w-auto h-auto px-2 py-2 rounded-md bg-yellow-400 text-sm font-bold uppercase">
                                  suivre mon colis
                    </motion.button>
                  </div>
              </div> 
        </div>

        <div className="w-full h-screen flex flex-row bg-white">
           
            <div className="w-1/2 h-full items-center justify-center flex flex-col bg-yellow-400">
              <span className="text-xl font-bold uppercase">livrons partout en Algérie</span>
              <span className="text-sm  text-center font-medium uppercase ">
              Nous offrons une logistique nationale avec une qualité de distribution optimale, et ce grâce à nos bureaux locaux et nos équipes dédiés de haut niveau.
              </span>
              <div id="svg_container" className="mt-4"></div>
            </div>

            <div className="w-1/2 h-full flex flex-col items-center py-8 px-4">
                <span className="text-3xl text-black font-black uppercase">envoyer un colis</span>
                <div className="w-full h-auto px-4 py-2 flex flex-row">
                <div className="w-1/2 h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">nom : </span>
                  <input type="text" className="w-full h-8  border-gray-700 border-b"/>
                </div>
                <div className="w-1/2 h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">prénom :</span>
                  <input type="text" className="w-full h-8 outline-none border-gray-700 border-b"/>
                </div>
                </div>
                <div className="w-full h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">numéro mobile :</span>
                  <input type="text" className="w-full h-8 outline-none border-gray-700 border-b"/>
                </div>
                <div className="w-full h-auto px-4 py-2 flex flex-row">
                <div className="w-1/2 h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">wilaya de départ : </span>
                  <input type="text" className="w-full h-8 outline-none border-gray-700 border-b"/>
                </div>
                <div className="w-1/2 h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">destination :</span>
                  <input type="text" className="w-full h-8 outline-none border-gray-700 border-b"/>
                </div>
                </div>
                <div className="w-full h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">poids (Kg) :</span>
                  <input type="text" className="w-full h-8 outline-none  border-gray-700 border-b"/>
                </div>
                <div className="w-full h-auto px-4 py-2 flex flex-col">
                  <span className="text-sm capitalize">taille du colis :</span>
                  <input type="text" className="w-full h-8 outline-none  border-gray-700 border-b"/>
                </div>
                <div className="w-full h-auto px-4 py-2 flex flex-col">
                  <button className="w-full h-12  bg-yellow-400 text-sm text-bold uppercase font-bold">ajouter mon colis</button>
                </div>
                <span className="text-sm capitalize">pour plus d'informations appelez le : <span className="text-red-400 font-bold">06 66 66 66 66</span></span>
            </div>
        </div>

      <div className="w-full h-screen bg-fixed bg-cover bg-no-repeat flex justify-center items-center" id="hero">
              
              <div className="w-1/4 h-72 bg-white mx-2 flex flex-col items-center justify-center px-2 py-2">
                <span className="text-sm font-bold text-center underline tex-black uppercase mb-2">E-commerce</span>
                <p className="text-xs text-center">
                prise en charge de la livraison des produits des boutiques électroniques, du commerce électronique en Algérie. Avec ou sans recouvrement.
                </p>
              </div>
              <div className="w-1/4 h-72 bg-white mx-2 flex flex-col items-center justify-center px-2 py-2">
                <span></span>
                <span className="text-sm font-bold text-center underline tex-black uppercase mb-2">Recouvrement</span>
                <p className="text-xs text-center">
                C’est la possibilité d’opter pour le paiement à la livraison, que ce sois pour le service courrier classique, ou bien le service e-commerce
                </p>
              </div>
      </div>


      <div className="w-full h-screen bg-gray-800 flex justify-center items-center text-white px-12" >
            <div className="w-1/3 h-full flex flex-col justify-center items-center">
            <span className="text-3xl font-black  text-yellow-400 uppercase underline mb-4">EXPRESS .</span>
                <p>
                    Opérateur de courrier Express en régime domestique. Notre mission principale est de nous assurer de transporter vos courriers et vos colis.
                </p>
            </div>
            
            <div className="w-1/6 h-full flex flex-col justify-center items-center">

            </div>

            <div className="w-1/3 h-full flex flex-col justify-center">
              <span className="text-3xl font-black  text-yellow-400 uppercase underline mb-4">Contact Info</span>
              <span className="text-sm pl-4 capitalize">E-mail : <span>contact@mail.com</span></span>
              <span className="text-sm pl-4 capitalize">mobile : <span>06 666 666 666</span></span>
              <span className="text-sm pl-4 capitalize">adress : <span>votre adresse </span></span>
            </div>
      </div>

    </div> 

    

    
    

    
</div>
    
  )
}

export default AllProd
